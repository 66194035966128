import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Spacer from "../components/Spacer";
import "../styles/contact.css";

const ContactPage = () => {
  return (
    <Layout pageTitle="Contact Page">
      <div className="mainpage contactpage">
        <div className="landinggrid left contactgrid">
          <div className="landingwhitespace"></div>
          <div className="landingwords text valuewords">
            <p>Let's talk</p>
          </div>

          <StaticImage
            alt="Contact Page"
            src="../images/contact-transformed.png"
            className="image landingimage"
          />
        </div>

        <Spacer classes="h50 mobilehide" />

        <article className="contactdetails text">
          <p>
            Not sure what to say? Don't worry, take your time and send us your
            queries through an email
          </p>
          <div className="email">
            <a href="mailto:happytohelp@teamana.in">happytohelp@teamana.in</a>
          </div>
          <div className="number">
            <a href="tel:+912025656542">+91-20-25656542</a>
          </div>
        </article>

        <Spacer classes="h50" />
      </div>
    </Layout>
  );
};

export const Head = () => <Seo title="Contact" />;

export default ContactPage;
